import {logger} from "@otto-ec/global-resources/debug";

const log = logger('ftnav.imberator.tracking');

/**
 *
 *
 *
 */
function submitToTracking(dataContainer, fn, type) {
    try {
        fn(dataContainer);
        log.debug(type + " submitting " + JSON.stringify(dataContainer));
    } catch (e) {
        log.debug(e);
    }
}

/**
 *
 *
 */

/*                                                                                    */
export function sendTrackingDataForNextPI(trackingData) {
    if (window.o_tracking.bct && typeof window.o_tracking.bct.trackOnNextPageImpression === 'function') {
        submitToTracking(trackingData, window.o_tracking.bct.trackOnNextPageImpression, "Track on next PI");
    }
}

/**
 *
 *
 */

/*                                                                              */
export function sendEventToTracking(dataContainer) {
    if (window.o_tracking.bct && typeof window.o_tracking.bct.sendEventToTrackingServer === 'function') {
        submitToTracking(dataContainer, window.o_tracking.bct.sendEventToTrackingServer, "Track event");
    }
}

export function sendMergeToTracking(dataContainer) {
    if (o_global && o_global.eventQBus && typeof o_global.eventQBus.emit === 'function') {
        log.debug('nav tracked ', dataContainer);

        o_global.eventQBus.emit('tracking.bct.addToPageImpression', dataContainer);
    }
}
