import {sendEventToTracking} from "./tracking";

/*                                                                     */
export function initToggle(opts) {
    let button = window.document.querySelector(opts.button_selector);

    if (button) {
        button.addEventListener('click', function (e) {
            button.classList.toggle(opts.class_name);
            let expander = window.document.querySelector(opts.expander_selector);
            if (expander) {
                expander.classList.toggle(opts.class_name);
                expander.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest'
                });
                /*                                                        */
                /*                         */
                let expander_height = expander.getBoundingClientRect().height;
                let label = (expander_height > 500) ? 'consultant_more' : 'consultant_less';
                sendEventToTracking({nav_Foldout: label});
            }
        });
    }
}
