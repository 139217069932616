import {initClickTracking} from "./contentTracking";
import {initToggle} from "./expander";
import {sendMergeToTracking} from "./tracking";

window.o_global.eventLoader.onReady(5, function () {
    initConsultant();
    initBottomLinks();
    initSidebarText();
});

window.o_global.eventQBus.on('ftfind.navSidebarText.loaded', initSidebarText);
window.o_global.eventQBus.on('ftfind.navBottomLinks.loaded', initBottomLinks);
window.o_global.eventQBus.on('ftfind.navConsultantText.loaded', initConsultant);

function initConsultant() {
    sendViewTracking('.nav-consultant-content', {nav_SeoConsultant: 'true'});
    initClickTracking('.nav-consultant-content a', 'seo_consultant');
    initToggle({
        button_selector: '.nav-expander-button',
        expander_selector: '.nav-content-expander',
        class_name: 'nav-expander-toggled'
    });
}

function initBottomLinks() {
    sendViewTracking('.nav-bottom-links', {nav_SeoBottomLinks: 'true'});
    initClickTracking('.nav-bottom-links a', 'seo_bottom_links');
}

function initSidebarText() {
    sendViewTracking('.nav-sidebar-content', {nav_SeoSidebarText: 'true'});
    initClickTracking('.nav-sidebar-text a', 'seo_sidebar_text');
}

function sendViewTracking(selector, labels) {
    if (document.querySelector(selector)) {
        sendMergeToTracking(labels);
    }
}
